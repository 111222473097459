html {
  font-family: Montserrat;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  color: white;
  font-size: 34px;
  margin-left: 20px;
}

.red-bg {
  background: #3a0d0d;
}

.nav-btn {
  transition: 0.7s;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 28px;
  color: white;
  padding: 10px;
  text-decoration: none;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 10px;
  border: 1px solid white;
  text-align: center;
}

.nav-btn:hover {
  transition: 0.7s;
  color:  #3a0d0d;
  background: white;
}

.nav-btn-inverted {
  transition: 0.7s;
  margin-right: 20px;
  font-size: 24px;
  color:#3a0d0d;
  padding: 10px;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #3a0d0d;
}

.nav-btn-inverted:hover {
  transition: 0.7s;
  color:  white;
  background: #3a0d0d;;
}

@media (max-width: 500px) {
  .nav-btn {
    font-size: 18px;
  }

  .nav-btn-inverted {
    font-size: 14px;
  }
}